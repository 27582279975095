import React, { useState, useEffect } from "react";
import "../styles/logout.css";
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import "../styles/button.css";

import { useTranslation} from "react-i18next";
import LanguageButtons from "../screens/LanguageButtons";

export default function Header() {
  const myLang = Cookies.get('i18next');



  console.log(myLang);
  function Arabic()
  {
      const [t, i18n] = useTranslation('common');
      return <div >
         <a class="dedcription-btn" onClick={() => i18n.changeLanguage('en')}>
          <span class="name-descripeion">English</span>
        <div class="btn-icon">
          <i class="far fa-lightbulb"></i>              </div>
        </a>
          {/* <button onClick={() => i18n.changeLanguage('en')}>en</button> */}

          
      </div>
  }
  function English()
  {
      const [t, i18n] = useTranslation('common');
      return <div >
           <a class="dedcription-btn" onClick={() => i18n.changeLanguage('fr')}>
          <span class="name-descripeion">Arabic</span>
          <div class="btn-icon book">
     <i class="fas fa-book-reader"></i>          </div>
        </a>
          
          {/* <button onClick={() => i18n.changeLanguage('fr')}>fr</button> */}
          
      </div>
  }

  


  function logout() {
    localStorage.clear();
    window.location.href = "/";
  }
  return (
    <div>
     
      <div
        className="container2 d-flex justify-content-center"
        data-aos="zoom-in"
      >
         {(myLang==='fr') ? <Arabic/> : <English/>}
        <img
          className="img-fluid img-style"
          
          src="images/sss.png"
          alt="restaurant bg"
        />
        <img
          className=" image2 img-style2"
          src="images/power.png"
          alt="restauran"
          onClick={logout}
        />
        
        
        
      </div>
    </div>
  );
}
