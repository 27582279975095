
import React, { useEffect, useState } from "react";
import ModalCard from "./ModalCard";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation} from "react-i18next";


export default function FoodItemList(props) {
  const { t } = useTranslation();
  const myLang = Cookies.get('i18next');

  
  // const { addItem, inCart, setCartMetadata } = useCart();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const food = props.food;
 
  const {
    addItem,
    inCart,
  
    items,

    updateItemQuantity,
   
  } = props.useCart;
  // console.log(food);
  const alreadyAdded = inCart(food.id);
  const item_inc = () => {

  
    return(<> 
    <h5 class="modal-header1 justify-content-center font-modal text-center p-1 mt-3 mb-3 ">
    Choose quantity
  </h5>
         {items.filter(person => person.id === food.id).map((item) => (
     
  <div className="d-flex justify-content-center">
   
    <div className="col-12 d-flex justify-content-center" key={item.id}>
      <button
        className="button-five m-2"
        
        onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
      >
        <i class="fa-solid fa-minus"></i>
      </button>
      <div className="button-five m-2"> 
      {item.quantity}
      </div>
      <button
        className="button-five m-2" 
        onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
   
  </div>
  
    ))}
  </>)
  // });
 
    }
    const Results = () => (

      
      <div className="button-block justify-content-center p-3" key={food.id}>
                  <div className="btn-block ">
                 
                  <Link to={"/orders"} style={{textDecoration:"none"}}>
                      <div className="button-colors icon-btn elevation-0 icon-text-btn v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default" style={{textDecoration:"none"}}>
                    
                     {t("Next")}
                    
                      </div>
                      </Link>   
                  </div>
                </div>
                
    )
    const Add = () => (

      <div className=" justify-content-center p-3" key={food.id}>
      <div className="btn-block">
     
     
          <div className="button-colors icon-btn elevation-0 icon-text-btn v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default" onClick={()=>{addItem(food)}}>
        
          {t("Add to cart")}
        
          </div>
             
      </div>
    </div>
    )



  return (
    <>
    <div className="container">
    <div className="row d-flex justify-content-center">
     <div className="col-12 col-sm-10 p-2 font-color">
      {/* {console.log(food.subcategory)} */}
        <div class="card curve shadow p-0 mb-0 bg-white rounded" onClick={handleShow} data-aos="fade-up" onHide={handleClose}>
          <div class="card-body " id={food.id} >
            <div className="row" >
              <div className="col-3 ">
                <img className="card-image" src={food.image}  alt="pic" />
              </div>
              
              <div className="col-9 ">
                <div className="prize-label button-colors  ">{food.price}
                  <div className="currency">{t("S.R")}</div>
                </div>

                <h5 class="card-title font-size card-heading text-left">
                   {(myLang==='fr') ? `${food.arabic}` : `${food.english}`}
                </h5>
                
                <p class="card-details  p-0 m-0 text-left">
               {(myLang==='fr') ? `${food.desarabic}` : `${food.des}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      
        {/* <CartProvider> */}
       
        <Modal className="card shadow" show={show} onHide={handleClose} >
      <Modal.Body  >
        {/* <ModalCard addItem={addItem} inCart={inCart} setCartMetadata={setCartMetadata} isEmpty={isEmpty}
        cartTotal={cartTotal} totalUniqueItems={totalUniqueItems} items={items} updateItemQuantity={updateItemQuantity}
        removeItem={removeItem} emptyCart={emptyCart} metadata={metadata}
        food={food} handleClose={handleClose}/> */}


 {/* start  */}

 <div className="d-flex justify-content-center" >
      {/* <CartProvider> */}
        <div className="container4" >
          <div className="col-12 col-sm-12 col-lg-12 col-md-12 p-3">
            <div className="card mt-4 shadow">
              <div className="back-button">
                <Modal.Header closeButton>
                  {/* <i class="fa-solid fa-arrow-left-long"  ></i> */}
                </Modal.Header>
              
              </div>

              <img className ="img-rounded modal-img" src={food.image} alt="Card image cap"/>

              <div class="card-body p-0 overflow-auto scroll" style={{height:"65rem"}}>
                <h5 class="modal-header justify-content-center font-modal text-center p-1 mt-2 mb-3">
                {(myLang==='fr') ? `${food.arabic}` : `${food.english}`}
                </h5>

                <p class="card-text card-details1 text-left p-3 m-1" >
                {(myLang==='fr') ? `${food.desarabic}` : `${food.des}`}
                </p>
                {alreadyAdded ?  item_inc() :  "" }
                {/* {console.log(props.items)} */}
             
              

                {alreadyAdded ? <Results /> : <Add/>}
                
              </div>

              <div className="button-block2 justify-content-center p-3" key={food.id}>
                  <div className="btn-block3 "  typeof="button" >
             
              <div variant="secondary"
       className=" button-colors icon-btn2 elevation-0 icon-text-btn3 v-btn3 v-btn--is-elevated v-btn--has-bg theme--light v-size--default"
         onClick={handleClose} >
            {t("Back")}
          </div>
          </div>
          </div>
            </div>
          </div>
        </div>
      {/* </CartProvider> */}
    
    </div>






{/* end  */}
























         
      </Modal.Body>
      <div className="col-12 col-sm-12 col-lg-12 col-md-12 p-3">
            <div className="card mt-5 shadow">
            <Modal.Footer>
            <div className="button-block justify-content-center p-3" style={{position:"relative",bottom:-471 ,right:130,alignItems:"center" }}>
                  <div className="btn-block pt-5"   typeof="button" >
     
          
          
          </div>
          </div>
         
        </Modal.Footer>
              </div></div>
     
    </Modal>
    {/* </CartProvider> */}
      </div>
      </div>
    </div>
    
      
      </>
      )

      
   
}
