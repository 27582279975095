import React, { useState } from 'react';
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FoodItemsSmall from './FoodItemsSmall';
import HorizontCards from './HorizontalCard';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




export default function Categories(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation ( )

  return (
    <div className="container2">
      
    <div className="d-flex justify-content-center ">
      <div className="shadow p-0 mb-5 bg-white rounded">
        <Card className="text-center">
          <Card.Header>
            <div className="d-flex justify-content-between" >
              
            <i class="fa-regular fa-clock fa-2x p-2 font-color" data-aos="fade-right" onClick={handleShow}></i>
        
            <div>

            {/* <i class="fa-brands fa-snapchat-square fa-2x p-2"></i> */}
            <a href='https://instagram.com/salkara_family_restaurant?igshid=YmMyMTA2M2Y='>
            <i class="fa-brands fa-instagram-square fa-2x p-2 "></i></a>
            {/* <i class="fa-brands fa-twitter-square fa-2x p-2"></i> */}
            </div>
            <a href='https://www.facebook.com/salkarakhobar'>
            <i class="fa-brands fa-facebook-f fa-2x p-2" data-aos="fade-left"></i>
            </a>
           
            
            </div>
          </Card.Header>
        
          <Card.Body>
            <Card.Title className=" heading-color p-3">{t('Categories')}</Card.Title>
            <div class="container-fluid">
           <HorizontCards categories = {props.categories} />
           </div>
        
           <FoodItemsSmall useCart={props.useCart} categories = {props.categories} foods = {props.foods}/>
            
          </Card.Body>
         
        </Card>
        <Modal show={show} onHide={handleClose} style={{height:700}}>
        <Modal.Header closeButton>
          <Modal.Title>Work Timings</Modal.Title>
        </Modal.Header>
        <Modal.Body >
      
          <div class="container m-1">
  <div class="row">
    <div class="col font-weight-bold">
    Monday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
    Tuesday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
    Wednesday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
   Thursday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
   Friday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
   Saturday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
  <div class="row">
    <div class="col">
    Sunday
    </div>
    <div class="col">
    : 10 am to 10 pm
    </div>
   
  </div>
</div>
        
        </Modal.Body>
     
      </Modal>
      </div>
    </div>
    </div>
  )
}
